import * as React from "react";
import {Box, Center, Heading} from "@chakra-ui/react";
import NavBar from "./components/Header"

import {Route, Routes} from "react-router-dom";
import EnterAddress from "./pages/EnterAddress"
import ShowWallet from "./pages/ShowWallet"
import AddressValidator from "./pages/AddressValidator";
import Filter from "./pages/Filter";

// const Filter = () => {
//     return (
//         <>
//             <Heading>Cohort</Heading>
//         </>
//     );
// }

const About = () => {
    return (
        <>
            <Heading>About</Heading>
        </>
    );
}

const Contact = () => {
    return (
        <>
            <Heading>Contact</Heading>
        </>
    );
}

const ConnectWallet = () => {
    return (
        <>
            <Heading>Connect Wallet</Heading>
        </>
    );
}

export const App = () => {
    return (
        <>
            <Box>
                <Box>
                    <NavBar/>
                </Box>
                <Box>
                    <Center>
                        <Routes>
                            {/*<Route path="/" element={<FrontPage/>}/>*/}
                            <Route path="/" element={<EnterAddress/>}/>
                            <Route path="/address" element={<EnterAddress/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/connect-wallet" element={<ConnectWallet/>}/>
                            <Route path="/show-wallet" element={<ShowWallet/>}/>
                            <Route path="/address-validator" element={<AddressValidator/>}/>
                            <Route path="/filter" element={<Filter/>}/>
                        </Routes>
                    </Center>
                </Box>
            </Box>
        </>
    );
}
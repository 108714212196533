import {useEffect} from "react";
import {hotjar} from "react-hotjar";
import ReactGA from "react-ga4";

const Analytics = (hotJarId: number, googleAnalyticsId: string) => {
    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            hotjar.initialize(hotJarId, 1);
            const url = window.location.pathname + window.location.search
            ReactGA.initialize(googleAnalyticsId);
            ReactGA.send({hitType: "pageview", page: url});
        }
    }, []);
}

export default Analytics;
import * as React from "react";
import {
    Container,
    Skeleton,
    Spacer,
    Spinner,
    Stack,
    Stat,
    StatArrow,
    StatHelpText,
    StatLabel,
    StatNumber
} from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import {ethers} from "ethers";
import ContentBox from "./ContentBox";
import ContentCard from "./ContentCard";

interface Volume {
    transactionCountIn: string;
    transactionCountOut: string;
    volumeInWei: string;
    volumeOutWei: string;
    volumeInUSD: string;
    volumeOutUSD: string;
}

const Content = (response: Volume) => {
    return (
        <>
            <Stat>
                <StatLabel>Last {response.transactionCountOut} txs</StatLabel>
                <StatNumber>
                    <NumberFormat
                        value={ethers.utils.formatEther(response.volumeOutWei)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                        decimalScale={2}
                    /> ETH
                </StatNumber>
                <StatHelpText>
                    ~
                    <NumberFormat
                        value={response.volumeOutUSD}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                    />
                </StatHelpText>
            </Stat>
        </>
    );
};

const VolumeOut = (hexAddress: string) => {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [response, setItems] = React.useState<Volume>();
    const [address, setAddress] = React.useState<string>();
    if (hexAddress !== address) {
        setIsLoaded(false);
        setAddress(hexAddress);
    }

    React.useEffect(() => {
        const apiUrl = `/api/address/${address}/volume`;
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [address])

    const headline = "Volume Out";
    const content = (thisResponse) => Content(thisResponse)
    return ContentCard(
        headline,
        content,
        error,
        isLoaded,
        response,
        150
    );
};

export default VolumeOut;
import * as React from "react"
import {
  chakra,
  keyframes,
  ImageProps,
  forwardRef,
} from "@chakra-ui/react"
import logo from "../assets/logo.png"

const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  return <a href="/"><chakra.img width="80px" src={logo} ref={ref} {...props} /></a>
})

export default Logo;
import * as React from "react";
import {Navigate} from 'react-router-dom';
import {Box, Center, Spinner, Text} from "@chakra-ui/react";
import QueryParams from "../components/utils/QueryParams";

const AddressValidator: () => JSX.Element = () => {
    let query = QueryParams();
    const rawAddress = query.get("address")

    const [error, setError] = React.useState(null);
    const [isInvalid, setIsInvalid] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [address, setAddress] = React.useState<string>();

    const _isEnsAddress = isEnsAddress(rawAddress);

    React.useEffect(() => {
        if (!rawAddress || !isValidAddress(rawAddress)) {
            // console.log(`invalid address: ${rawAddress}`)
            setIsLoaded(true);
            setIsInvalid(true);
            setError(error);
        } else if (_isEnsAddress) {
            convertEnsToHexAddress(rawAddress)
                .then(
                    (convertedAddress) => {
                        if (!convertedAddress) {
                            // console.log(`Fail: ${rawAddress} => ${convertedAddress}`);
                            setIsLoaded(true);
                            setIsInvalid(true);
                        } else {
                            // console.log(`Loaded: ${rawAddress} => ${convertedAddress}`);
                            setIsLoaded(true);
                            setAddress(convertedAddress);
                        }
                    },
                    (error) => {
                        // console.log(`Fail: error: ${error}`)
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        } else {
            // console.log(`Normal address: ${rawAddress}`)
            setIsLoaded(true);
            setAddress(rawAddress);
        }
    }, [])

    const queryParams =
        _isEnsAddress ? `address=${address}&ens=${rawAddress}`
            : `address=${address}`

    if (isInvalid) {
        return (
            <Navigate to={`/address?${queryParams}`}/>
        );
    } else if (!error && address && address?.length > 0 && isLoaded) {
        return (
            <Navigate to={`/show-wallet?${queryParams}`}/>
        );
    } else {
        const text = _isEnsAddress ? "Resolving ENS address" : "Looking up address";
        return (
            <>
                <Box>
                    <Text>
                        {text}
                    </Text>
                    <Center>
                        <pre>{rawAddress}</pre>
                    </Center>
                    <br/>
                    <Center>
                        <Spinner/>
                    </Center>
                </Box>
            </>
        );
    }
};

function isEnsAddress(address: string | null): boolean {
    return address != null && address.includes("\.eth");
}

interface EnsResponse {
    address?: string,
    reverseAddress?: string,
}

function api<T>(url: string): Promise<T> {
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
}

function convertEnsToHexAddress(ens: string | null): Promise<string | undefined | void> {
    if (!ens) return Promise.resolve();

    const url = `/api/ens/${ens}`;
    const call: Promise<string | undefined | void> = api<EnsResponse>(url)
        .then(data => {
            return data.address;
        })
        .catch(error => {
            /* show error message */
        })

    return call;
}

function isValidAddress(address: string | null): boolean {
    if (!address) return false;
    else if (isEnsAddress(address)) return true;
    else return (address.length > 40 && address.length < 45);
}

export default AddressValidator;
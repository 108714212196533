import {Button, useDisclosure} from "@chakra-ui/react";
import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
} from '@chakra-ui/react'

const ConnectWalletFeature = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const recordConnectWallet = async () => {
        onOpen();
        fetch(`/api/feature/connect-wallet/value/plus`,
            {
                method: "post",
                body: null,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    }

    return (
        <>
                <Button
                    fontWeight={{base: "normal", md: "bold"}}
                    size="md"
                    rounded="md"
                    color={{base: "black", md: "white"}}
                    bg={{base: "white", md: "#7928CA"}}
                    _hover={{bg: ["primary.100", "primary.100", "primary.600", "primary.600"]}}
                    onClick={async () => (await recordConnectWallet()) }
                >
                    Connect Wallet
                </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Connect Wallet</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Coming soon!</Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='purple' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ConnectWalletFeature;
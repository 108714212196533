import * as React from "react";
import {Box} from "@chakra-ui/react";
import QueryParamsFilterConfig, {convertQueryToFiltersRequest} from "../components/utils/QueryParamsFilterConfig";
import FilterSelection from "../components/filter/FilterSelection";
import DownloadReportModal from "../components/filter/DownloadReportModal";
import {FiltersConfiguration} from "../components/filter/FiltersConfiguration";
import FilterResultCard from "../components/filter/FilterResultCard";
import FilterDownloadCard from "../components/filter/FilterDownloadCard";

const Filter: () => JSX.Element = () => {
    const queryParams = QueryParamsFilterConfig();
    const filtersConfiguration = convertQueryToFiltersRequest(Array.from(queryParams.entries()))

    // console.log(`Loaded filters: ${JSON.stringify(filtersConfiguration, null, 2)}`)

    return FilterUI(filtersConfiguration);
    // return <></>;
};

const FilterUI: (
    filtersConfiguration: FiltersConfiguration,
) => JSX.Element = (filtersConfiguration: FiltersConfiguration) => {
    return (
        <Box width={"80%"}>
            {FilterSelection(filtersConfiguration)}
            <br/>
            {FilterResultCard(filtersConfiguration)}
            <br/>
            {/*<FilterDownloadCard />*/}
            {/*<br/>*/}
            {DownloadReportModal(filtersConfiguration)}

        </Box>
    );
}


export default Filter;
import * as React from "react";
import {Alert, AlertIcon, Badge, Flex, Spinner, Stack, Tooltip} from "@chakra-ui/react";
import {ethers} from "ethers";
import AddressBar from "./AddressBar";
import AddressListComponent from "./AddressList";
import EnsLookup from "./EnsLookup";

interface TypeResponse {
    addressType: string;
}

function checksum(address) {
    try {
        ethers.utils.getAddress(address);
        return false;
    } catch {
        return true;
    }
}

const WalletStatsComponent = (hexAddress: string, ens?: string | null) => {

        const [error, setError] = React.useState(null);
        const [isLoaded, setIsLoaded] = React.useState(false);
        const [response, setItems] = React.useState<TypeResponse>();
        const [address, setAddress] = React.useState<string>();
        if (hexAddress !== address) {
            setIsLoaded(false);
            setAddress(hexAddress);
        }

        React.useEffect(() => {
            const apiUrl = `/api/address/${address}/type`;
            fetch(apiUrl)
                .then(res => res.json())
                .then(
                    (result) => {
                        const converted = (result && result.addressType == "external") ? "Private" : "Contract"
                        const convertedData = {
                            "addressType": converted
                        }
                        // console.log(convertedData);
                        setIsLoaded(true);
                        setItems(convertedData);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
            return function cleanup() {
                setIsLoaded(false);
            };
        }, [address])

        if (error) {
            return <div>Error</div>;
        } else if (!isLoaded || !response) {
            return (
                <>
                    <div>Loading...</div>
                    <Stack>
                        <Spinner/>
                    </Stack>
                </>
            );
        } else {
            const isPrivate = response.addressType === "Private";
            const hasBadChecksum = checksum(hexAddress);
            const formattedAddress = hasBadChecksum ? hexAddress : ethers.utils.getAddress(hexAddress)

            return (
                <>
                    <Flex
                        w={"100%"}
                        direction={"column"}
                    >
                        <div>
                            <AddressBar address={formattedAddress}/>
                        </div>
                        <br/>
                        <div>
                            {isPrivate ? (
                                <>
                                    <Tooltip label="This type of address is often owned by an individual">
                                        <Badge
                                            colorScheme='purple'
                                            variant='solid'
                                            fontSize='1em'
                                            borderRadius="4px"
                                            p={2}
                                        >
                                            {response.addressType}
                                        </Badge>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Tooltip
                                        label="This type of address belongs to a smart contract and is not managed by a person">
                                        <Badge
                                            variant='outline'
                                            fontSize='1em'
                                            borderRadius="4px"
                                            p={2}
                                        >
                                            {response.addressType}
                                        </Badge>
                                    </Tooltip>
                                </>
                            )}
                            <br/>
                            <br/>
                            <AddressListComponent hexAddress={formattedAddress}/>
                        </div>
                        <br/>
                        {hasBadChecksum ? (
                            <>
                                <Alert
                                    status='error'
                                    borderRadius="4px"
                                >
                                    <AlertIcon/>
                                    Fails checksum verification
                                </Alert>
                            </>
                        ) : (
                            <>
                                <Alert
                                    status='success'
                                    borderRadius="4px"
                                >
                                    <AlertIcon/>
                                    Address checksum verified
                                </Alert>
                            </>
                        )
                        }
                        <br/>
                        <div>
                            <EnsLookup hexAddress={formattedAddress} ens={ens}/>
                        </div>
                    </Flex>
                </>
            );
        }
    }
;

export default WalletStatsComponent;
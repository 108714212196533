import * as React from "react";
import {Alert, AlertIcon, Badge, Box, Spinner, Stack} from "@chakra-ui/react";


interface ListEntry {
    address: string,
    name: string,
    description: string,
    url: string,
    date: string,
    labels: string[],
}

function AddressListComponent(props) {
    const {hexAddress} = props;
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [response, setItems] = React.useState<ListEntry[]>();

    React.useEffect(() => {
        const apiUrl = `/api/address/${hexAddress}/list`;
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <div>Error</div>;
    } else if (!isLoaded || !response) {
        return (
            <>
                <div>Loading...</div>
                <Stack>
                    <Spinner/>
                </Stack>
            </>
        );
    } else {
        return (
            <>
                <Box>
                    <div>
                        {response && (response.map((entry, index) => {
                                switch (entry.labels[0]) {
                                    case "verified":
                                        return (
                                            <Alert
                                                key={index}
                                                status='success'
                                                borderRadius='4px'
                                            >
                                                <AlertIcon/>
                                                {/*<Badge colorScheme="pink">verified</Badge>*/}
                                                This address has been verified by tilli:<br/>
                                                {entry.date}{entry.name && `, ${entry.name}`}{entry.description && `, ${entry.description}`}
                                            </Alert>
                                        );
                                    case "safe":
                                        return (
                                            <Alert
                                                key={index}
                                                status='success'
                                                borderRadius='4px'
                                            >
                                                <AlertIcon/>
                                                This address has been confirmed to be safe by other users
                                            </Alert>
                                        );
                                    case "fraud":
                                        return (
                                            <Alert
                                                key={index}
                                                status='error'
                                                borderRadius='4px'
                                            >
                                                <AlertIcon/>
                                                This address has been confirmed to be related with fraudulent activity:<br/>
                                                {entry.date}{entry.name && `, ${entry.name}`}{entry.description && `, ${entry.description}`}
                                            </Alert>
                                        );
                                    default:
                                        return (
                                            <Alert
                                                key={index}
                                                status='warning'
                                                borderRadius='4px'
                                            >
                                                <AlertIcon/>
                                                We don't have any threat information regarding this address, proceed with caution
                                            </Alert>
                                        );
                                }
                            }
                        ))
                        }

                        {response.length == 0 && (
                            <Alert
                                status='warning'
                                borderRadius='4px'
                            >
                                <AlertIcon/>
                                We don't have any threat information regarding this address, proceed with caution
                            </Alert>
                        )}
                    </div>
                </Box>
            </>
        );
    }
}

export default AddressListComponent;
import React from "react";
import {Box, Center, Image, Link, Text, VStack} from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import {ethers} from "ethers";

const NftCard = (
    {
        tokenAddress,
        contractType,
        collectionName,
        symbol,
        name,
        imageUrl,
        description
    }: {
        tokenAddress: string,
        contractType: string,
        collectionName: string,
        symbol: string,
        name: string,
        imageUrl: string,
        description: string
    }) => {
    return (
        <Box
            w={32}
            h={48}
            borderRadius={"lg"}
            borderWidth={"1px"}
        >
            <VStack>
                <Link href={imageUrl}>
                    <Box
                        overflow={"hidden"}
                        borderRadius={"lg"}
                        m={0}
                        maxW={32}
                        maxH={32}
                        w={32}
                    >
                    <Image
                        src={imageUrl}
                        fallbackSrc='https://styles.redditmedia.com/t5_2wlj3/styles/communityIcon_7jxh2j4ouky41.png?width=256&s=59ea46d93492e9d0951b43d7c580f72982a86974'
                        // https://www.freeiconspng.com/uploads/no-image-icon-6.png
                    />
                    </Box>
                </Link>
                <Box p={2}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={"12"}
                        noOfLines={1}
                        maxW={"32"}
                        paddingLeft={1}
                    >
                        {collectionName}
                    </Text>
                    <Text
                        // fontWeight={"bold"}
                        fontSize={"10"}
                        noOfLines={1}
                        maxW={"24"}
                        paddingLeft={1}
                    >
                        <Link
                            href={`https://etherscan.io/address/${tokenAddress}`}
                        >
                            {name} ({symbol})
                        </Link>
                    </Text>
                </Box>
            </VStack>
        </Box>
    );
};

export default NftCard;
import {SimpleFilter} from "./FiltersConfiguration";

export const defaultOffset: number = 0
export const defaultPageSize: number = 50
export const defaultReturnTotal: boolean = true;

export const filterQueryParamName = "filters"
export const filterQueryPageSizeParamName = "page_size"
export const filterQueryOffsetParamName = "offset"
export const filterQueryReturnTotal = "return_total";

export const VALUES = {
    TEXT: "text",
    ADDRESS: "address",
    INTEGER: "integer",
    DOUBLE: "double",
    BOOLEAN: "boolean",
}

export const OPERATORS = {
    LESS_THAN: "lt",
    GREATER_THAN: "gt",
    EQUAL: "eq"
}

export const OPERATORS_NUMBERS = [
    OPERATORS.LESS_THAN,
    OPERATORS.GREATER_THAN,
    OPERATORS.EQUAL,
];

export const OPERATORS_BOOLEAN = [
    OPERATORS.EQUAL,
];

export const OPERATORS_ADDRESS = [
    OPERATORS.EQUAL,
];

export const DIMENSIONS = {
    ADDRESS: "address",
    ASSET_CONTRACT_ADDRESS: "asset_contract_address",
    ASSET_CONTRACT_COUNT: "asset_contract_count",
    HOLD_TIME_AVG: "hold_time_avg",
    HOLD_TIME_MAX: "hold_time_max",
    HOLD_TIME_MIN: "hold_time_min",
    MINT_COUNT: "mint_count",
    TRANSACTION_COUNT: "transaction_count",
    TOKEN_COUNT: "token_count",
    HAS_MINTED: "has_minted",
}

export const DIMENSIONS_LIST: (string)[] = [
    DIMENSIONS.ADDRESS,
    DIMENSIONS.ASSET_CONTRACT_ADDRESS,
    DIMENSIONS.ASSET_CONTRACT_COUNT,
    DIMENSIONS.HOLD_TIME_AVG,
    DIMENSIONS.HOLD_TIME_MAX,
    DIMENSIONS.HOLD_TIME_MIN,
    DIMENSIONS.MINT_COUNT,
    DIMENSIONS.TRANSACTION_COUNT,
    DIMENSIONS.TOKEN_COUNT,
    DIMENSIONS.HAS_MINTED,
]

export const DIMENSION_OPERATOR_MAP = new Map([
    [DIMENSIONS.ADDRESS, OPERATORS_ADDRESS],
    [DIMENSIONS.ASSET_CONTRACT_ADDRESS, OPERATORS_ADDRESS],
    [DIMENSIONS.ASSET_CONTRACT_COUNT, OPERATORS_NUMBERS],
    [DIMENSIONS.HOLD_TIME_AVG, OPERATORS_NUMBERS],
    [DIMENSIONS.HOLD_TIME_MAX, OPERATORS_NUMBERS],
    [DIMENSIONS.HOLD_TIME_MIN, OPERATORS_NUMBERS],
    [DIMENSIONS.MINT_COUNT, OPERATORS_NUMBERS],
    [DIMENSIONS.TRANSACTION_COUNT, OPERATORS_NUMBERS],
    [DIMENSIONS.TOKEN_COUNT, OPERATORS_NUMBERS],
    [DIMENSIONS.HAS_MINTED, OPERATORS_BOOLEAN],
])

export const DIMENSION_VALUES_MAP = new Map([
    [DIMENSIONS.ADDRESS, VALUES.ADDRESS],
    [DIMENSIONS.ASSET_CONTRACT_ADDRESS, VALUES.ADDRESS],
    [DIMENSIONS.ASSET_CONTRACT_COUNT, VALUES.INTEGER],
    [DIMENSIONS.HOLD_TIME_AVG, VALUES.DOUBLE],
    [DIMENSIONS.HOLD_TIME_MAX, VALUES.DOUBLE],
    [DIMENSIONS.HOLD_TIME_MIN, VALUES.DOUBLE],
    [DIMENSIONS.MINT_COUNT, VALUES.INTEGER],
    [DIMENSIONS.TRANSACTION_COUNT, VALUES.INTEGER],
    [DIMENSIONS.TOKEN_COUNT, VALUES.INTEGER],
    [DIMENSIONS.HAS_MINTED, VALUES.BOOLEAN],
])
export const DIMENSION_LABELS_UI = new Map<string, string>([
    [DIMENSIONS.ADDRESS, "Address"],
    [DIMENSIONS.ASSET_CONTRACT_ADDRESS, "Contract Address"],
    [DIMENSIONS.ASSET_CONTRACT_COUNT, "Number of Contracts"],
    [DIMENSIONS.HOLD_TIME_AVG, "Hold Time Avg"],
    [DIMENSIONS.HOLD_TIME_MAX, "Hold Time Max"],
    [DIMENSIONS.HOLD_TIME_MIN, "Hold Time Min"],
    [DIMENSIONS.MINT_COUNT, "Number of Mints"],
    [DIMENSIONS.TRANSACTION_COUNT, "Number of Transactions"],
    [DIMENSIONS.TOKEN_COUNT, "Number of Tokens"],
    [DIMENSIONS.HAS_MINTED, "Has Minted"],
]);

export const OPERATOR_LABELS_UI = new Map<string, string>([
    [OPERATORS.LESS_THAN, "<"],
    [OPERATORS.GREATER_THAN, ">"],
    [OPERATORS.EQUAL, "="],
]);

export const DefaultDimension = DIMENSIONS.HOLD_TIME_AVG
export const DefaultOperator = OPERATORS.EQUAL

// export const defaultFilterConfiguration: FilterConfiguration = {
//     dimension: DefaultDimension,
//     operator: OPERATORS.GREATER_THAN,
//     value: "500",
//     pageSize: defaultPageSize,
//     offset: 0,
// }

export const defaultFilterConfiguration: SimpleFilter = {
    dimension: DefaultDimension,
    operator: OPERATORS.GREATER_THAN,
    value: "500",
}
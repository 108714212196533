import * as React from "react";
import {Box, Flex, Spinner, Text} from "@chakra-ui/react";
import TokenCard from "./TokenCard";
import ContentBox from "./ContentBox";
import ContentCard from "./ContentCard";

interface Token {
    contractAddress: string;
    value: string;
    rawValue: string;
    valueUSD: string;
    tokenName: string;
    tokenSymbol: string;
    tokenDecimal: string;
    imageUrl: string;
}

const Content = (tokens: Token[]) => {
    return (
        <Flex
            flex={1}
            direction={"row"}
            overflowX="auto"
        >
            {tokens && (tokens
                    .map((entry: Token, index) => {
                        return (
                            <>
                                <Box p={2}>
                                    <TokenCard
                                        key={index}
                                        imageSrc={entry.imageUrl}
                                        tokenName={entry.tokenName}
                                        tokenSymbol={entry.tokenSymbol}
                                        tokenValue={entry.value}
                                        valueUSD={entry.valueUSD}
                                    />
                                </Box>
                            </>
                        );
                    })
            )}
        </Flex>
    );
}

const Tokens = (hexAddress: string) => {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [response, setItems] = React.useState<Token[]>();
    const [address, setAddress] = React.useState<string>();
    if (hexAddress !== address) {
        setIsLoaded(false);
        setAddress(hexAddress);
    }

    React.useEffect(() => {
        const apiUrl = `/api/address/${address}/tokens`;
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(`result ${result}`)
                    setIsLoaded(true);
                    setItems(result.tokens);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [address])

    const headline = "Tokens";
    const content = (thisResponse) => Content(thisResponse)
    return ContentCard(
        headline,
        content,
        error,
        isLoaded,
        response,
    );
};

export default Tokens;
import React from "react";
import {Box, Center, Image, Text, VStack} from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import {ethers} from "ethers";

const TokenCard = (
    {
        imageSrc,
        tokenName,
        tokenSymbol,
        tokenValue,
        valueUSD
    }:
        { imageSrc: string, tokenName: string, tokenSymbol: string, tokenValue: string, valueUSD?: string }) => {
    return (
        <Box
            w={24}
            h={"100%"}
            borderRadius={"lg"}
            borderWidth={"1px"}
            p={2}
        >
            <VStack>
                <Image
                    m={1}
                    src={imageSrc}
                    w={"32px"}
                    fallbackSrc='https://styles.redditmedia.com/t5_2wlj3/styles/communityIcon_7jxh2j4ouky41.png?width=256&s=59ea46d93492e9d0951b43d7c580f72982a86974'
                />
                <Box>
                    <Center>
                        <Text
                            fontWeight={"bold"}
                            fontSize={"10"}
                            noOfLines={1}
                            maxW={"20"}
                        >
                            {tokenName}
                        </Text>
                    </Center>
                    <Box
                        alignContent={"right"}
                    >
                        <Text
                            align={"center"}
                            fontSize={"8"}
                            noOfLines={1}
                            maxW={"20"}
                        >
                            <NumberFormat
                                value={tokenValue}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={''}
                                decimalScale={2}
                            /> {tokenSymbol}
                        </Text>
                        {valueUSD && (
                            <Text
                                align={"center"}
                                fontSize={"8"}
                                noOfLines={1}
                                maxW={"20"}
                            >~
                                <NumberFormat
                                    value={valueUSD}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                /> {valueUSD && ("USD")}
                            </Text>
                        )}
                    </Box>
                </Box>
            </VStack>
        </Box>
    );
};

export default TokenCard;
import {useLocation} from "react-router-dom";
import * as React from "react";
import {
    defaultOffset,
    defaultPageSize,
    defaultReturnTotal,
    filterQueryOffsetParamName,
    filterQueryPageSizeParamName,
    filterQueryParamName,
    filterQueryReturnTotal
} from "../filter/Filters";
import {FiltersConfiguration, SimpleFilter} from "../filter/FiltersConfiguration";

const QueryParamsFilterConfig = () => {
    const {search} = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(search);
}

export const serializeFiltersRequestToQuery: (filtersConfiguration: FiltersConfiguration) => string = (filtersConfiguration) => {
    const field: (index: number, fieldName: string, value: string) => string = (index: number, fieldName: string, value: string) => {
        return `${filterQueryParamName}.${index}.${fieldName}=${encodeURIComponent(value)}`;
    }
    const encodedFilterParams = filtersConfiguration.filters
        .flatMap((value, index) =>
            [
                field(index, "dimension", value.dimension as string),
                field(index, "operator", value.operator as string),
                field(index, "value", value.value as string),
            ]
        )
        .join("&")

    const fullQuery = `${encodedFilterParams}&${filterQueryParamName}.${filterQueryOffsetParamName}=${encodeURIComponent(filtersConfiguration.offset)}&${filterQueryParamName}.${filterQueryPageSizeParamName}=${encodeURIComponent(filtersConfiguration.pageSize)}`
    return fullQuery;
}

export const convertQueryToFiltersRequest: (queryParams: [string, string][]) => FiltersConfiguration = (queryParams) => {
    const filters = getAllFilters(queryParams)
    return {
        filters: filters,
        pageSize: getPageSize(queryParams),
        offset: getOffset(queryParams),
        returnTotal: getReturnTotal(queryParams),
    } as FiltersConfiguration;
}

const getReturnTotal: (queryParams: [string, string][]) => boolean = (queryParams) => {
    const temp = queryParams
        .filter(e => e[0].startsWith(`${filterQueryParamName}.${filterQueryReturnTotal}`))
        .filter(e => e[1] && e[1].length > 0)
        .map((value => value[1].toLowerCase() === "true"))

    if (temp && temp.length > 0 && temp[0] !== null) return temp[0];
    else return defaultReturnTotal;
};

const getPageSize: (queryParams: [string, string][]) => number = (queryParams) => {
    const temp = queryParams
        .filter(e => e[0].startsWith(`${filterQueryParamName}.${filterQueryPageSizeParamName}`))
        .filter(e => e[1] && e[1].length > 0)
        .map((value => +value[1]))

    if (temp && temp.length > 0 && temp[0] !== null) return temp[0];
    else return defaultPageSize;
};

const getOffset: (queryParams: [string, string][]) => number = (queryParams) => {
    const temp = queryParams
        .filter(e => e[0].startsWith(`${filterQueryParamName}.${filterQueryOffsetParamName}`))
        .filter(e => e[1] && e[1].length > 0)
        .map((value => +value[1]))

    if (temp && temp.length > 0 && temp[0] !== null) return temp[0];
    else return defaultOffset;
};

const getAllFilters: (queryParams: [string, string][]) => SimpleFilter[] = (queryParams) => {
    const regex: RegExp = new RegExp('^([^.]*)\\.(.*)');
    const filtered = queryParams
        .filter(e => e[0].startsWith(filterQueryParamName))
        .map(e => [e[0].substring(filterQueryParamName.length + 1), e[1]])
        .map(e => {
            const matches = regex.exec(e[0]);
            if (!matches || matches[1] == null || matches[2] == null) return [];
            return [matches[1], matches[2], e[1]];
        })
        .filter(e => e.length > 0)

    const grouped = groupBy(filtered, e => e[0]);
    return Object
        .entries(grouped)
        .map((value, index, arr) => (
            {
                dimension: !value[1][0][2] ? null: value[1][0][2],
                operator: !value[1][1][2] ? null : value[1][1][2],
                value: !value[1][2] ? null : value[1][2][2],
            } as SimpleFilter
        ));
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

export default QueryParamsFilterConfig;
import {
    Box,
    Button,
    Flex,
    IconButton,
    Input, NumberDecrementStepper, NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Text,
} from "@chakra-ui/react";
import ContentCard from "../ContentCard";
import * as React from "react";
import {ChangeEvent, useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {
    DefaultDimension,
    DIMENSION_LABELS_UI,
    DIMENSION_OPERATOR_MAP,
    DIMENSION_VALUES_MAP,
    DIMENSIONS_LIST,
    filterQueryOffsetParamName,
    filterQueryPageSizeParamName,
    filterQueryParamName,
    OPERATOR_LABELS_UI, VALUES
} from "./Filters";
import {FiltersConfiguration, SimpleFilter} from "./FiltersConfiguration";
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldValues} from "react-hook-form/dist/types/fields";

const emptyFilter = () => {
    return {
        dimension: null,
        operator: null,
        value: null,
    } as SimpleFilter;
}

const dimensionOptions = DIMENSIONS_LIST.map(key => {
    return (
        <option key={key} value={key}>{DIMENSION_LABELS_UI.get(key)}</option>
    )
}, this);

const operatorOptions = (
    filter: SimpleFilter,
) => {
    const filterDimension = filter == null ? DefaultDimension : filter.dimension;
    const selectedDimension = filterDimension == null ? DefaultDimension : filterDimension;
    const operators = DIMENSION_OPERATOR_MAP.get(selectedDimension);
    return (
        <>
            {operators && operators.map((v, i) =>
                <option key={v} value={v}>{OPERATOR_LABELS_UI.get(v)}</option>
            )}
        </>
    )
};

const valueInput = (
    filter: SimpleFilter,
    width: string,
    padding: string,
    rowNumber: number,
    getSelectedValue: (number) => string | number | ReadonlyArray<string> | undefined,
    updateFiltersOnValueChange: (row: number, ev: React.ChangeEvent<HTMLInputElement>) => void,
    updateFiltersOnValueChangeString: (row: number, value: string) => void,
    register: UseFormRegister<FieldValues>,
) => {
    const filterDimension = filter == null ? DefaultDimension : filter.dimension;
    const selectedDimension = filterDimension == null ? DefaultDimension : filterDimension;
    const valueType = DIMENSION_VALUES_MAP.get(selectedDimension);
    switch (valueType) {
        case VALUES.ADDRESS.valueOf(): {
            return (
                <>
                    <Input
                        key={`value-${rowNumber}`}
                        w={width}
                        mr={padding}
                        defaultValue={getSelectedValue(rowNumber)}
                        {...register(`${filterQueryParamName}.${rowNumber}.value`, {
                            required: true,
                            minLength: 40,
                            maxLength: 45,
                            onChange: (ev => updateFiltersOnValueChange(rowNumber, ev))
                        })}
                    >
                    </Input>
                </>
            )
            break;
        }
        case VALUES.DOUBLE.valueOf():
            const field = register(`${filterQueryParamName}.${rowNumber}.value`, {
                required: true,
            })
            return (
                <>
                    <NumberInput
                        key={`value-${rowNumber}`}
                        w={width}
                        mr={padding}
                        min={0}
                        step={0.5}
                        precision={2}
                        keepWithinRange={true}
                        clampValueOnBlur={true}
                        defaultValue={getSelectedValue(rowNumber) as number}
                        name={field.name}
                        onChange={ev => {
                            field.onChange({target: ev})
                            updateFiltersOnValueChangeString(rowNumber, ev)
                        }}
                        ref={field.ref}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                </>
            )
        case VALUES.INTEGER.valueOf(): {
            const field = register(`${filterQueryParamName}.${rowNumber}.value`, {
                required: true,
            })
            return (
                <>
                    <NumberInput
                        key={`value-${rowNumber}`}
                        w={width}
                        mr={padding}
                        min={0}
                        step={1}
                        precision={0}
                        keepWithinRange={true}
                        clampValueOnBlur={true}
                        defaultValue={getSelectedValue(rowNumber) as number}
                        name={field.name}
                        onChange={ev => {
                            field.onChange({target: ev})
                            updateFiltersOnValueChangeString(rowNumber, ev)
                        }}
                        ref={field.ref}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                </>
            )
            break;
        }
        case VALUES.BOOLEAN.valueOf(): {
            return (
                <>
                    <Select
                        key={`value-${rowNumber}`}
                        w={width}
                        mr={padding}
                        defaultValue={getSelectedValue(rowNumber)}
                        {...register(`${filterQueryParamName}.${rowNumber}.value`, {
                            required: true,
                            onChange: (ev => updateFiltersOnValueChange(rowNumber, ev))
                        })}
                    >
                        <option value={"true"}>true</option>
                        <option value={"false"}>false</option>
                    </Select>
                </>
            )
            break;
        }
        case VALUES.TEXT.valueOf():
        default: {
            return (
                <>
                    <Input
                        key={`value-${rowNumber}`}
                        w={width}
                        mr={padding}
                        defaultValue={getSelectedValue(rowNumber)}
                        {...register(`${filterQueryParamName}.${rowNumber}.value`, {
                            required: true,
                            onChange: (ev => updateFiltersOnValueChange(rowNumber, ev))
                        })}
                    >
                    </Input>
                </>
            )
            break;
        }
    }

    return (
        <>
            <Input
                key={`value-${rowNumber}`}
                w={width}
                mr={padding}
                defaultValue={getSelectedValue(rowNumber)}
                {...register(`${filterQueryParamName}.${rowNumber}.value`, {
                    required: true,
                    onChange: (ev => updateFiltersOnValueChange(rowNumber, ev))
                })}
            >
            </Input>
        </>
    )
};

const FilterSelection: (
    filtersConfiguration: FiltersConfiguration,
) => JSX.Element = (filtersConfiguration: FiltersConfiguration) => {
    const {
        register,
        control,
        handleSubmit,
        reset,
        watch
    } = useForm();
    // console.log(`FilterSelection got filtersConfiguration: ${JSON.stringify(filtersConfiguration, null, 2)}`)

    if (filtersConfiguration.filters.length < 1) {
        filtersConfiguration.filters = [emptyFilter()];
    }

    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: filterQueryParamName, // unique name for your Field Array
    });

    const [filters, setFilters] = useState<FiltersConfiguration>(filtersConfiguration);

    useEffect(() => {
        reset(filters);
        console.log(`Filters got updated: ${JSON.stringify(filters, null, 2)}`);
    }, []);

    const updateFiltersOnDimensionChange = (
        row: number,
        ev: ChangeEvent<HTMLSelectElement>,
    ) => {
        if (ev === null || ev.target.value === null || ev.target.value.length < 1) return;
        const newDimension = ev.target.value;
        // console.log(`row ${row}: ${!filters.filters[row] ? null : filters.filters[row].dimension} -> ${newDimension}`)
        const newSimpleFilter =
            !filters.filters[row] ?
                filters.filters[row] = {
                    dimension: newDimension,
                    operator: null,
                    value: null,
                    returnTotal: null,
                } as SimpleFilter
                : {
                    dimension: newDimension,
                    operator: filters.filters[row].operator,
                    value: filters.filters[row].value,
                } as SimpleFilter

        const simpleFilters = filters.filters
        simpleFilters[row] = newSimpleFilter;
        // TODO: Is this a react idiomatic way of updating nested state?
        setFilters(
            {
                filters: simpleFilters,
                pageSize: filters.pageSize,
                offset: filters.offset,
                returnTotal: filters.returnTotal,
            }
        );
        const newValue = cleanData(newSimpleFilter.dimension as string, simpleFilters[row].value)
        // console.log(`dimension changed. Updating the value: ${newSimpleFilter.value} -> ${newValue} `)
        updateFiltersOnValueChangeString(
            row,
            newValue
        )
    }

    const updateFiltersOnOperatorChange = (
        row: number,
        ev: ChangeEvent<HTMLSelectElement>,
    ) => {
        if (ev === null || ev.target.value === null || ev.target.value.length < 1) return;
        const newOperator = ev.target.value;

        // console.log(`row ${row}: ${!filters.filters[row] ? null : filters.filters[row].operator} -> ${newOperator}`)
        const newSimpleFilter =
            !filters.filters[row] ?
                filters.filters[row] = {
                    dimension: null,
                    operator: newOperator,
                    value: null,
                } as SimpleFilter
                : {
                    dimension: filters.filters[row].dimension,
                    operator: newOperator,
                    value: filters.filters[row].value,
                } as SimpleFilter

        const simpleFilters = filters.filters
        simpleFilters[row] = newSimpleFilter;
        // TODO: Is this a react idiomatic way of updating nested state?
        setFilters(
            {
                filters: simpleFilters,
                pageSize: filters.pageSize,
                offset: filters.offset,
                returnTotal: filters.returnTotal,
            }
        );
    }

    const updateFiltersOnValueChange: (row: number, ev: React.ChangeEvent<HTMLInputElement>) => void = (
        row: number,
        ev: ChangeEvent<HTMLInputElement>,
    ) => {
        if (ev === null || ev.target.value === null || ev.target.value.length < 1) return;
        const newValue = ev.target.value;
        updateFiltersOnValueChangeString(row, newValue);
    }

    const updateFiltersOnValueChangeString: (
        row: number,
        newValue: string,
    ) => void = (
        row: number,
        newValue: string,
    ) => {
        // console.log(`row ${row}: ${!filters.filters[row] ? null : filters.filters[row].value} -> ${newValue}`)
        const newSimpleFilter =
            !filters.filters[row] ?
                filters.filters[row] = {
                    dimension: null,
                    operator: null,
                    value: newValue,
                } as SimpleFilter
                : {
                    dimension: filters.filters[row].dimension,
                    operator: filters.filters[row].operator,
                    value: newValue,
                } as SimpleFilter

        const simpleFilters = filters.filters
        simpleFilters[row] = newSimpleFilter;
        // TODO: Is this a react idiomatic way of updating nested state?
        setFilters(
            {
                filters: simpleFilters,
                pageSize: filters.pageSize,
                offset: filters.offset,
                returnTotal: filters.returnTotal,
            }
        );
    }

    // @ts-ignore
    const getSelectedDimension: (row: number) => string = (
        row: number
    ) => {
        // console.log(`Get dimension for row ${row}: ${!filters.filters[row] ? null : filters.filters[row].dimension}`)
        if (filters.filters[row] == null) return "";
        if (filters.filters[row].dimension == null) return "";
        return filters.filters[row].dimension == null ? "" : filters.filters[row].dimension;
    }

    // @ts-ignore
    const getSelectedOperator: (row: number) => string = (
        row: number
    ) => {
        if (filters.filters[row] == null) return "";
        if (filters.filters[row].operator == null) return "";
        return filters.filters[row].operator == null ? "" : filters.filters[row].operator;
    }

    // @ts-ignore
    const getSelectedValue: (row: number) => string = (
        row: number
    ) => {
        if (filters.filters[row] == null) return "";
        return cleanData(filters.filters[row].dimension as string, filters.filters[row].value);
    }

    const cleanData = (
        dimension: string,
        value: string | null,
    ): string => {
        const valueType = DIMENSION_VALUES_MAP.get(dimension);
        switch (valueType) {
            case VALUES.ADDRESS.valueOf(): {
                if (!value) return ""
                else return value
                break;
            }
            case VALUES.INTEGER.valueOf(): {
                if (!value) return "0"
                else {
                    const parsed = parseInt(value)
                    if(!isNaN(parsed)) return `${parsed}`
                    else return "0"
                }
                break;
            }
            case VALUES.DOUBLE.valueOf(): {
                if (!value) return "0.00"
                else {
                    const parsed = parseFloat(value)
                    if(!isNaN(parsed)) return `${parsed}`
                    else return "0"
                }
                break;
            }
            case VALUES.BOOLEAN.valueOf(): {
                if (value && value.toLowerCase() === "true") return "true"
                else return "false"
                break;
            }
            default: {
                if (!value) return ""
                else return value
                break;
            }
        }
    }

    const selectRow = () => {
        return (
            <>
                <form>
                    {fields && fields.map((field, i) =>
                        <Flex
                            key={`flex-${i}`}
                            mb={"15px"}
                        >
                            <Select
                                key={field.id}
                                w={defaultWidth}
                                mr={defaultPadding}
                                placeholder='Dimension'
                                defaultValue={getSelectedDimension(i)}
                                {...register(`${filterQueryParamName}.${i}.dimension`, {
                                    required: true,
                                    onChange: (ev => updateFiltersOnDimensionChange(i, ev))
                                })}
                            >
                                {dimensionOptions}
                            </Select>
                            <Select
                                key={`operator-${i}`}
                                w={"100px"}
                                mr={defaultPadding}
                                defaultValue={getSelectedOperator(i)}
                                placeholder='Operator'
                                {...register(`${filterQueryParamName}.${i}.operator`, {
                                    required: true,
                                    onChange: (ev => updateFiltersOnOperatorChange(i, ev))
                                })}
                            >
                                {operatorOptions(filters.filters[i])}
                            </Select>
                            {valueInput(
                                filters.filters[i],
                                defaultWidth,
                                defaultPadding,
                                i,
                                getSelectedValue,
                                updateFiltersOnValueChange,
                                updateFiltersOnValueChangeString,
                                register,
                            )}
                            <IconButton
                                size="md"
                                fontSize="md"
                                variant="ghost"
                                color="#7131FA"
                                marginLeft="2"
                                onClick={ev => remove(i)}
                                icon={<CloseIcon/>}
                                aria-label={`Delete`}
                            />
                            <IconButton
                                size="md"
                                fontSize="md"
                                variant="ghost"
                                color="#7131FA"
                                marginLeft="2"
                                onClick={ev => insert(i + 1, emptyFilter())}
                                icon={<AddIcon/>}
                                aria-label={`Add`}
                            />
                        </Flex>
                    )}
                    <input
                        type="hidden"
                        {...register(`${filterQueryParamName}.${filterQueryOffsetParamName}`)}
                        defaultValue={filters.offset}
                    />
                    <input
                        type="hidden"
                        {...register(`${filterQueryParamName}.${filterQueryPageSizeParamName}`)}
                        defaultValue={filters.pageSize}
                    />
                    <br/>
                    <Button
                        fontWeight={{base: "normal", md: "bold"}}
                        size="md"
                        rounded="md"
                        color={{base: "white"}}
                        bg={{base: "#7928CA"}}
                        _hover={{bg: ["primary.100", "primary.100", "primary.600", "primary.600"]}}
                        type="submit"
                    >
                        Run
                    </Button>
                </form>
            </>
        )
    }

    const isLoaded = true;
    const error = false;
    const response = {};

    const headline = "Filters"
    const defaultWidth = "220px"
    const defaultPadding = "5px"

    const content = (_) => {
        return (
            <>
                <Text
                    color={"grey"}
                    fontSize={"3xs"}
                >Find addresses who match the following characteristics
                </Text>
                <br/>
                <Box>
                    {selectRow()}
                </Box>
            </>
        )
    }

    return ContentCard(
        headline,
        content,
        error,
        isLoaded,
        response,
    );
};

export default FilterSelection;
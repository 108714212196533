import * as React from "react";
import {
    Box,
    Link,
    Skeleton,
    Spinner,
    Stack,
    Table,
    TableCaption,
    Tag,
    Tbody,
    Td, Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {ethers} from "ethers";

import Moment from 'react-moment';
import NumberFormat from "react-number-format";

import momentJS from 'moment';
import ContentBox from "./ContentBox";
import ContentCard from "./ContentCard";

interface Transaction {
    transactionHash: string;
    timestamp: string;
    from: string;
    to: string;
    value: string;
    tokenSymbol: string,
    tokenName: string,
}

const TransactionHistoryContent = (response: Transaction[], hexAddress: string) => {

    const isIn = (address: string, recipient: string) => {
        return address.toLowerCase() === recipient.toLowerCase();
    }

    return (
        <Box
            maxHeight="lg"
            overflowY="auto"
        >
            <Table
                variant="striped"
                fontSize={"sm"}
            >
                {response && (
                    <TableCaption>Account History (last {response.length} entries)</TableCaption>
                )}

                <Thead position="sticky">
                    <Tr>
                        <Th maxW="150px">Transaction</Th>
                        <Th>&nbsp;</Th>
                        <Th>Amount</Th>
                        <Th>Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {response.map((transaction, index) => (
                            <Tr key={index}>
                                <Td
                                    maxW="150px"
                                    title={transaction.transactionHash}
                                ><Link
                                    noOfLines={1}
                                    href={"https://etherscan.io/tx/" + transaction.transactionHash}
                                >
                                    {transaction.transactionHash}
                                </Link>
                                </Td>
                                <Td>
                                    {isIn(hexAddress, transaction.to)
                                        ? <Tag colorScheme={"teal"}>IN</Tag>
                                        : <Tag colorScheme={"yellow"}>OUT</Tag>
                                    }
                                </Td>
                                <Td
                                    title={ethers.utils.formatEther(transaction.value) + " " + transaction.tokenSymbol}
                                >
                                    <NumberFormat
                                        value={ethers.utils.formatEther(transaction.value)}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={''}
                                        decimalScale={4}
                                    /> {transaction.tokenSymbol}
                                </Td>
                                <Td>
                                    <p
                                        title=
                                            {momentJS(new Date(parseInt(transaction.timestamp) * 1000)).utc(false).format("YYYY-MM-DD HH:mm:ss") + " UTC"}
                                    >
                                        <Moment fromNow={true}
                                                date={new Date(parseInt(transaction.timestamp) * 1000).toJSON()}

                                        />
                                    </p>
                                </Td>
                            </Tr>
                        )
                    )}
                </Tbody>
                <Tfoot>
                </Tfoot>
            </Table>
        </Box>
    );

}

const TransactionHistory = (hexAddress: string) => {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [response, setItems] = React.useState<Transaction[]>();
    const [address, setAddress] = React.useState<string>();
    if (hexAddress !== address) {
        setIsLoaded(false);
        setAddress(hexAddress);
    }

    React.useEffect(() => {
        const apiUrl = `/api/address/${address}/history`;
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result: { entries: Transaction[]; }) => {
                    setIsLoaded(true);
                    setItems(result.entries);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [address])

    const headline = "Transaction History";
    const content = (thisResponse) => TransactionHistoryContent(thisResponse, hexAddress)
    return ContentCard(
        headline,
        content,
        error,
        isLoaded,
        response,
    );
};

export default TransactionHistory;
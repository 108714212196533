import ContentBox from "./ContentBox";
import {Spinner} from "@chakra-ui/react";
import * as React from "react";

const ContentCard = (
    headline,
    content,
    error,
    isLoaded,
    response,
    minW: number = 200
) => {

    if (error) {
        return ContentBox(
            headline,
            <div>Error loading {headline.toLowerCase()}</div>,
            minW,
        );
    } else if (!isLoaded) {
        return ContentBox(
            headline,
            <><Spinner/></>,
            minW,
        );
    } else if (!response) {
        return ContentBox(
            headline,
            <></>,
            minW,
        );
    } else {
        return ContentBox(
            headline,
            content(response),
            minW,
        );
    }

}

export default ContentCard;
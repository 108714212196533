import React from "react";
import ReactDOM from "react-dom/client";
import {App} from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import {ChakraProvider} from "@chakra-ui/react";
import {BrowserRouter} from "react-router-dom";
import theme from './theme/'
import './theme/styles.css'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <ChakraProvider theme={theme}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ChakraProvider>
);

serviceWorker.unregister()
reportWebVitals()

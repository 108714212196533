import * as React from "react";
import {Container, Skeleton, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber} from "@chakra-ui/react";
import NumberFormat from 'react-number-format';
import {Spinner} from '@chakra-ui/react'
import ContentBox from "./ContentBox";
import ContentCard from "./ContentCard";

interface BalanceResponse {
    balance: string;
    symbol: string,
    balanceUSD: string;
}

const Content = (response: BalanceResponse) => {
    return (
        <>
            <Stat>
                {/*<StatLabel>Balance</StatLabel>*/}
                <StatNumber>
                    <NumberFormat
                        value={response.balance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                        decimalScale={4}
                    /> {response.symbol}
                </StatNumber>
                <StatHelpText>
                    ~
                    <NumberFormat
                        value={response.balanceUSD}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                    />
                </StatHelpText>
            </Stat>
        </>
    );
}

const Balance = (hexAddress: string) => {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [response, setItems] = React.useState<BalanceResponse>();
    const [address, setAddress] = React.useState<string>();
    if (hexAddress !== address) {
        setIsLoaded(false);
        setAddress(hexAddress);
    }

    React.useEffect(() => {
        const apiUrl = `/api/address/${address}/balance`;
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [address])

    const headline = "Balance";
    const content = (thisResponse) => Content(thisResponse)
    return ContentCard(
        headline,
        content,
        error,
        isLoaded,
        response,
    );
};

export default Balance;
import * as React from "react";
import {Box, Heading, Spacer, Text, VStack} from "@chakra-ui/react";
import AddressBar from "../components/AddressBar";
import QueryParams from "../components/utils/QueryParams";
import Analytics from "../components/utils/Analytics";

const EnterAddress = (props) => {
    const {enableTry: boolean} = props;
    Analytics(2907129,"G-BESERB52NN" );
    let query = QueryParams();
    const address = query.get("address")
    return (
        <Box>
            <VStack>
                <VStack
                    p={2}
                >
                    <Heading
                        as="h1"
                        bgGradient="linear(to-l, #7928CA, #FF0080)"
                        bgClip="text"
                        fontSize={["5xl", "6xl"]}
                        fontWeight="extrabold"
                        textAlign={"center"}
                    >
                        Find your tribe on-chain
                    </Heading>
                    <Text
                        as="h2"
                        fontSize={["xl", "2xl"]}
                        fontWeight="normal"
                    >
                        Build your community with tilli
                    </Text>
                </VStack>

                <Spacer/><Spacer/><Spacer/><Spacer/><Spacer/><Spacer/>

                <AddressBar address={address} enableTry={true}/>
            </VStack>
        </Box>
    );
}

export default EnterAddress;
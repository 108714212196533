import {Box, Text} from "@chakra-ui/react";
import * as React from "react";

const ContentBox = (
    headline: string,
    content: any,
    minW: number,
    ) => {
    return (
        <Box
            border="1px"
            borderRadius="12px"
            rounded={"12px"}
            padding="24px"
            boxSizing="border-box"
            alignItems="flex-start"
            borderColor="#E2E8F0"
            minW={`${minW}px`}
            w={"100%"}
            // border="1px"
            // borderRadius="12px"
            // borderColor="#E2E8F0"
            // padding="24px"
            // width={"100%"}

        >
            <Text pb={4} fontSize={"md"} fontWeight={"bold"}>{headline}</Text>
            {content}
        </Box>
    );
}

export default ContentBox;
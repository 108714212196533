import * as React from "react";
import {Box, Button, IconButton, Input, InputGroup, InputRightAddon} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons"
import {useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";

const AddressBar = (props) => {
    const {address, enableTry} = props;
    const navigate = useNavigate();
    const exampleAddress = "0xd8da6bf26964af9d7eed9e03e53415d37aa96045"; //"vitalik.eth";
    const defaultAddress = null;
    const [processedAddress, setProcessedAddress] = React.useState<string | undefined>(!address || address === "undefined" ? defaultAddress : address);

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm();

    const onSubmit = (data) => {
        navigate(`/address-validator?address=${data.address}`, {state: data});
    };

    const handleClick = () => {
        setProcessedAddress(exampleAddress);
        onSubmit({address: exampleAddress});
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <InputGroup>
                        <Input
                            type="text"
                            size="md"
                            width="md"
                            variant="outline"
                            defaultValue={processedAddress}
                            color={"gray.500"}
                            placeholder="address or ENS domain name"
                            {...register("address", {
                                required: "Please enter an Ethereum address",
                                // minLength: 40,
                                // maxLength: 45
                            })}
                            w={["150px", "300px", "400px", "500px"]}
                        />
                        <InputRightAddon
                            children={
                                <IconButton
                                    size="sm"
                                    width="100%"
                                    aria-label='Look up address'
                                    type="submit"
                                    icon={
                                        <SearchIcon
                                            color="black.500"
                                        />
                                    }
                                />
                            }
                        />
                        {enableTry && (
                            <Button
                                ml={"10px"}
                                onClick={handleClick}
                                fontWeight={"normal"}
                            >
                                Example
                            </Button>
                        )
                        }
                    </InputGroup>
                </Box>
            </form>
        </Box>
    );
}

export default AddressBar;
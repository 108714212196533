import * as React from "react";
import {Alert, AlertIcon, Badge, Box, Link, Spinner, Stack, Tooltip} from "@chakra-ui/react";


interface EnsResponse {
    address?: string,
    reverseAddress?: string,
}

interface TwitterResponses {
    profiles: TwitterResponse[];
}

interface TwitterResponse {
    name?: string,
    handle?: string,
    description?: string,
    url?: string,
    imageUrl?: string,
    followersCount?: number,
    friendsCount?: number,
}

function EnsLookup(props) {
    const {hexAddress, ens} = props;

    const [ensError, setEnsError] = React.useState(null);
    const [ensIsLoaded, setEnsIsLoaded] = React.useState(false);
    const [ensResponse, setEnsResponse] = React.useState<EnsResponse>();

    const [twitterError, setTwitterError] = React.useState(null);
    const [twitterIsLoaded, setTwitterIsLoaded] = React.useState(false);
    const [twitterResponse, setTwitterResponse] = React.useState<TwitterResponse>();
    const [twitterMultipleResponse, setTwitterMultipleResponse] = React.useState(false);

    React.useEffect(() => {
        const apiUrl = `/api/ens/${hexAddress}`;
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result: EnsResponse) => {
                    setEnsIsLoaded(true);
                    setEnsResponse(result);
                    return result;
                },
                (error) => {
                    setEnsIsLoaded(true);
                    setEnsError(error);
                }
            )
            .then((ensResponse) => {
                if (ensResponse && ensResponse.reverseAddress && ensResponse.reverseAddress.length > 2) {
                    const apiUrl = `/api/twitterHandle/${ensResponse.reverseAddress}`;
                    fetch(apiUrl)
                        .then(res => res.json())
                        .then(
                            (result: TwitterResponses) => {
                                // console.log(result.profiles)
                                if (result.profiles.length > 1) setTwitterMultipleResponse(true);
                                if (result.profiles.length > 0) setTwitterResponse(result.profiles[0]);
                                setTwitterIsLoaded(true);
                            },
                            (error) => {
                                setTwitterIsLoaded(true);
                                setTwitterError(error);
                            }
                        )
                } else {
                    setTwitterIsLoaded(true);
                }
            })
    }, [])

    if (ensError) {
        return <div>Error</div>;
    } else if (!ensIsLoaded || !ensResponse) {
        return (
            <>
                <div>Loading...</div>
                <Stack>
                    <Spinner/>
                </Stack>
            </>
        );
    } else {
        const ensFinal: string | null = ensResponse.reverseAddress ? ensResponse.reverseAddress : ens ? ens : null
        return (
            <>
                <Box>
                    <Alert
                        status='info'
                        borderRadius="4px"
                    >
                        <AlertIcon/>
                        ENS Record:&nbsp;
                        {ensFinal ? (
                            <Link href={`https://app.ens.domains/name/${ensFinal}/details`}>
                                {ensFinal}
                            </Link>
                        ) : (
                            <>Not Found</>
                        )
                        }

                    </Alert>
                </Box>
                {twitterIsLoaded && twitterResponse && (
                    <>
                        <br/>
                        <Box>
                            <div>
                                <Alert
                                    status='info'
                                    borderRadius="4px"
                                >
                                    <AlertIcon/>
                                    Twitter account
                                    {twitterMultipleResponse && (
                                        <Tooltip
                                            label={"This address was potentially linked to multiple twitter handles. We are showing the likely most relevant account"}>
                                            *
                                        </Tooltip>
                                    )}
                                    : &nbsp;
                                    <Tooltip
                                        label={`${twitterResponse.friendsCount} following, ${twitterResponse.followersCount} followers`}>
                                        <Link href={twitterResponse.url}>@{twitterResponse.handle}</Link>
                                    </Tooltip>
                                </Alert>
                            </div>
                        </Box>
                    </>
                )
                }
            </>
        );
    }
}

export default EnsLookup;
import * as React from "react";
import {Navigate} from 'react-router-dom';
import {Box, Flex} from "@chakra-ui/react";
import NftsComponent from "../components/Nfts"
import WalletStatsComponent from "../components/WalletStats";
import Balance from "../components/Balance";
import TransactionHistory from "../components/TransactionHistory";
import TransactionVolumeIn from "../components/TransactionVolumeIn";
import TransactionVolumeOut from "../components/TransactionVolumeOut";
import Tokens from "../components/Tokens";
import QueryParams from "../components/utils/QueryParams";
import Analytics from "../components/utils/Analytics";

const ShowWallet: () => JSX.Element = () => {
    let query = QueryParams();
    const address = query.get("address")
    const ens = query.get("ens")
    if (address == null || !isValidAddress(address)) return navigateToMainPage(address);
    else return WalletComponents(address, ens);
};

function navigateToMainPage(address: string | null) {
    return (
        <Navigate to={`/address?address=${address}`}/>
    );
}

function isValidAddress(address: string | null): boolean {
    if (!address) return false;
    else return (address.length > 40 && address.length < 45);
}

function WalletComponents(hexAddress: string, ens?: string | null) {
    Analytics(2905250, "G-BESERB52NN");
    return (
        <>
            <Flex
                maxW="80%"
                maxH="80%"
                direction={"column"}
            >
                <Flex p={2}>
                    <Box
                        w={{base: "100%", sm: "40%"}}
                        p={2}
                    >
                        {WalletStatsComponent(hexAddress, ens)}
                    </Box>
                </Flex>
                <Flex
                    direction={{base: 'column', sm: 'row'}}
                >
                    <Box
                        w={{base: "100%", sm: "40%"}}
                    >
                        <Flex p={2}>{Balance(hexAddress)}</Flex>
                        <Flex>
                            <Flex w={"50%"} maxW={"50%"} p={2}>{TransactionVolumeIn(hexAddress)}</Flex>
                            <Flex w={"50%"} maxW={"50%"} p={2}>{TransactionVolumeOut(hexAddress)}</Flex>
                        </Flex>
                        <Flex w={"100%"} p={2}>{Tokens(hexAddress)}</Flex>
                    </Box>
                    <Box
                        p={2}
                        w={{base: "100%", sm: "60%"}}
                        h={"full"}
                    >
                        {TransactionHistory(hexAddress)}
                    </Box>
                </Flex>
                <Flex
                    p={2}
                >
                    {NftsComponent(hexAddress)}
                </Flex>
            </Flex>
        </>
    );
}

export default ShowWallet;
import * as React from "react";
import {Box, Flex, Spinner} from "@chakra-ui/react";
import NftCard from "./NftCard";
import ContentBox from "./ContentBox";
import ContentCard from "./ContentCard";

interface Nft {
    tokenAddress: string,
    contractType: string,
    collectionName: string,
    symbol: string,
    name: string,
    imageUrl: string,
    description: string,
}

interface NftResponse {
    nfts: Nft[];
}

const createApiAddress = (hexAddress: string) => {
    return `/api/address/${hexAddress}/nfts`;
}

const Content = (response: NftResponse) => {
    return (
        <Flex
            flex={1}
            direction={"row"}
            overflowX="auto"
        >
            {response && (response
                    .nfts
                    .map((entry: Nft, index) => {
                        return (
                            <>
                                <Box p={2}>
                                    <NftCard
                                        key={index}
                                        tokenAddress={entry.tokenAddress}
                                        contractType={entry.contractType}
                                        collectionName={entry.collectionName}
                                        symbol={entry.symbol}
                                        name={entry.name}
                                        imageUrl={entry.imageUrl}
                                        description={entry.description}
                                    />
                                </Box>
                            </>
                        );
                    })
            )}
        </Flex>
    );
}

const NftsComponent = (hexAddress: string) => {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [response, setItems] = React.useState<NftResponse>();
    const [address, setAddress] = React.useState<string>("");
    if (hexAddress !== address) {
        setIsLoaded(false);
        setAddress(hexAddress);
    }

    React.useEffect(() => {
        const apiUrl = createApiAddress(address);
        fetch(apiUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [address])

    const headline = "NFTs";
    const content = (thisResponse) => Content(thisResponse)
    return ContentCard(
        headline,
        content,
        error,
        isLoaded,
        response,
    );
}

export default NftsComponent;
import {
    Button,
    FormControl,
    FormErrorMessage,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import React from "react";
import DownloadRequest from "./DownloadRequest";
import {FiltersConfiguration} from "./FiltersConfiguration";

const validateEmail: (email) => RegExpMatchArray | null = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const EmailInput = (input, setInput) => {
    // const [input, setInput] = React.useState('')

    const handleInputChange = (e) => setInput(e.target.value)
    const isError = () => validateEmail(input) == null;

    return (
        <FormControl isInvalid={isError()}>
            {/*<FormLabel>Email</FormLabel>*/}
            <Input
                type='email'
                value={input}
                onChange={handleInputChange}
                placeholder={"vitalik@gmail.com"}
                mt={"20px"}
            />
            {!isError() ? (
                // <FormHelperText>Enter the email where you'd like to receive the link to the report</FormHelperText>
                <></>
            ) : (
                <FormErrorMessage>A valid email is required</FormErrorMessage>
            )}
        </FormControl>
    )
}

const DownloadReportModal = (filtersConfiguration: FiltersConfiguration) => {

    const [emailInput, setEmailInput] = React.useState('')
    const {isOpen, onOpen, onClose} = useDisclosure()

    const recordDownloadReport = async () => {
        const submission: DownloadRequest = {
            filtersConfiguration: filtersConfiguration,
            email: emailInput,
        }
        // console.log(submission);
        fetch(`/api/feature/filter/value/download-report`,
            {
                method: "post",
                body: JSON.stringify(submission),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        onClose();
    }

    return (
        <>
            <Button
                fontWeight={{base: "normal", md: "bold"}}
                size="md"
                rounded="md"
                color={{base: "white"}}
                bg={{base: "#7928CA"}}
                _hover={{bg: ["primary.100", "primary.100", "primary.600", "primary.600"]}}
                type="submit"
                onClick={onOpen}
                // disabled
            >
                Download Report
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Download Report</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Text>Enter your email and we will send over a csv file with the full list of addresses</Text>
                        {EmailInput(emailInput, setEmailInput)}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            colorScheme='purple' mr={3}
                            // onClick={onClose}
                            onClick={async () => (await recordDownloadReport())}
                        >
                            Send
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default DownloadReportModal;
import * as React from "react";
import {Box, Link, Table, Tbody, Td, Tfoot, Th, Thead, Tr, Text} from "@chakra-ui/react";
import ContentCard from "../ContentCard";
import NumberFormat from "react-number-format";
import {FiltersConfiguration} from "./FiltersConfiguration";
import {serializeFiltersRequestToQuery} from "../utils/QueryParamsFilterConfig";

interface AssetAnalytics {
    address: string;
    tokenId: string;
    assetContractAddress: string;
    assetContractName: string;
    assetContractType: string;
    count: number,
    duration: number,
    originatedFromNullAddress: boolean,
    transactions: string[],
}

interface UserAnalytics {
    address: string;
    holdTimeAvg: number | null;
    holdTimeMax: number | null;
    holdTimeMin: number | null;
    mints: number | null;
    transactions: number | null,
    tokens: number | null,
    assetContracts: string[],
    assetContractCount: number | null,
}

interface Result {
    entries: AssetAnalytics[],
    total: number | null,
}

interface UserAnalyticsResult {
    entries: UserAnalytics[],
    total: number | null,
}

const FilterResultContent = (
    response: UserAnalyticsResult,
) => {

    return (
        <Box
            maxHeight="2xl"
            overflowY="auto"
        >
            <Table
                variant="striped"
                fontSize={"sm"}
            >
                <Thead position="sticky">
                    <Tr>
                        <Th>Address</Th>
                        <Th>HT AVG</Th>
                        <Th>HT MIN</Th>
                        <Th>HT MAX</Th>
                        <Th>Transactions</Th>
                        <Th>Tokens</Th>
                        <Th>Mints</Th>
                        <Th>Contracts</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {response.entries.map((user, index) => (
                            <Tr key={index}>
                                <Td
                                    maxW="100px"
                                    title={user.address}
                                >
                                    <Link
                                        noOfLines={1}
                                        href={"https://alpha-1.tilli.app/show-wallet?address=" + user.address}
                                    >
                                        {user.address}
                                    </Link>
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.holdTimeAvg}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.holdTimeMin}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                    />
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.holdTimeMax}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                    />
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.transactions}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                    />
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.tokens}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                    />
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.mints}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                    />
                                </Td>
                                <Td
                                    textAlign={"right"}
                                >
                                    <NumberFormat
                                        value={user.assetContractCount}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                    />
                                </Td>
                            </Tr>
                        )
                    )}
                </Tbody>
                <Tfoot>
                </Tfoot>
            </Table>

        </Box>
    );

}

const FilterResultCard = (
    filtersConfiguration: FiltersConfiguration,
) => {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(true);
    const [response, setResponse] = React.useState<Result>();

    const encodedQueryParams = serializeFiltersRequestToQuery(filtersConfiguration)
    const callApi = filtersConfiguration.filters.length > 0 && filtersConfiguration.filters[0].dimension
    // console.log(encodedQueryParams);

    const returnTotal = "true"
    React.useEffect(() => {
        if(callApi) {
            setIsLoaded(false);
            const apiUrl = `/api/filter/?returnTotal=${returnTotal}&${encodedQueryParams}`;
            // console.log(`calling api: ${apiUrl}`)
            fetch(apiUrl)
                .then(res => res.json())
                .then(
                    (result: Result) => {
                        setIsLoaded(true);
                        setResponse(result);
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }
    }, [callApi])

    if (response) {
        const pageStart = filtersConfiguration.offset
        const pageEnd = filtersConfiguration.offset + filtersConfiguration.pageSize
        const count = response?.total ? ` (${pageStart}-${pageEnd} of ${response.total.toLocaleString()} rows)` : "";
        const headline = `Result${count}`;
        const content = (thisResponse) => FilterResultContent(thisResponse)
        return ContentCard(
            headline,
            content,
            error,
            isLoaded,
            response,
        );
    } else {
        const headline = `Results`;
        const content = (thisResponse) => <Text>Run query to show results</Text>
        return ContentCard(
            headline,
            content,
            error,
            isLoaded,
            true,
        );
    }
};


export default FilterResultCard;